// import * as calcTypes from '../Calculator/store/questions/questions.types';

let _IS_GOOGLE_RECAPTCHA_ENABLED = false;
let _GOOGLE_SITE_KEY = null;
const isGrecaptchaScriptLoaded = () => window?.grecaptcha !== undefined;

export const setGrecaptchaSiteKey = (isEnabled, siteKey) => {
  _IS_GOOGLE_RECAPTCHA_ENABLED = isEnabled;
  _GOOGLE_SITE_KEY = siteKey;
};

// TODO: we need to unify the stores of each applications in order to be able
// to run grecaptcha from a central point and control error messages from here.
// I'm going to leave this code commented for now because it is a good starting point.

// const grecaptchaTrackedActions = [...Object.values(calcTypes)];

// export const executeGrecaptcha = (store) => (next) => (action) => {
//   if (
//     _IS_GOOGLE_RECAPTCHA_ENABLED &&
//     grecaptchaTrackedActions.includes(action.type)
//   ) {
//     if (isGrecaptchaScriptLoaded()) {
//       window.grecaptcha.ready(() => {
//         try {
//           window.grecaptcha
//             .execute(_GOOGLE_SITE_KEY, {
//               action: action.type,
//             })
//             .then((token) =>
//               store.dispatch({
//                 type: 'captcha_challenge',
//                 data: { token },
//               })
//             );
//         } catch (error) {
//           store.dispatch({
//             type: 'captcha_challenge',
//             data: { isSuccess: false, error },
//           });
//         }
//       });
//     } else {
//       store.dispatch({
//         type: 'captcha_challenge',
//         data: { isSuccess: false, error: 'script not loaded' },
//       });
//     }
//   }
//   next(action);
// };

export const executeGrecaptcha = (action) => {
  if (!_IS_GOOGLE_RECAPTCHA_ENABLED) return Promise.resolve();
  return new Promise((resolve, reject) => {
    if (isGrecaptchaScriptLoaded()) {
      window.grecaptcha.ready(() => {
        try {
          window.grecaptcha
            .execute(_GOOGLE_SITE_KEY, {
              action: action,
            })
            .then(resolve);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    } else {
      console.error('recaptcha not imported');
      reject(new Error('Google recaptcha script not imported.'));
    }
  });
};
